import React from 'react';
import { Link } from 'gatsby';
import { buttonContainer, button, arrow, arrowDiv } from './ViewMoreButton.css'

const ViewMoreButton = React.memo(() => {

    return (
        <>
            <Link to='/about' className={buttonContainer}>
                <button className={button} >VIEW MORE</button><div className={arrowDiv}><i className={arrow}></i></div>
            </Link>
        </>
    );
});

export default ViewMoreButton;
