import React, { useContext } from 'react';
import { GatsbyContext } from '../context/context';

import CategoryProducts from '../components/organisms/WordPress/CategoryProducts';
import { OgOneColumnHero } from '../components/organisms/Heroes/OgOneColumnHero';
import { Helmet } from 'react-helmet-async';
import Member from '../components/organisms/WordPress/Member';
import TextImage01 from '../components/organisms/WordPress/TextImage01';
import TextImage02 from '../components/organisms/WordPress/TextImage02';
import AutoSlider from '../components/organisms/WordPress/AutoSlider';
import SNS from '../components/organisms/WordPress/SNS';
import Title from '../components/organisms/WordPress/Title';
import { useGetWordpressPostByCategorySettings } from '../hooks/useGetWordpressPostByCategorySettings';
import SEO from '../utils/seo';

export default function Index() {
  const { loading: postLoading, data: postLists } =
    useGetWordpressPostByCategorySettings({
      slug: 'event',
      count: 3,
      nextId: '',
    });
  const { isSidebarOpen } = useContext(GatsbyContext);

  if (postLoading) {
    return <div></div>;
  }

  const postItems = postLists.categories.edges[0].node.posts.edges;

  return (
    <>
      <SEO title={''} />
      {!isSidebarOpen && (
        <>
          <Helmet></Helmet>
          <div>
            <OgOneColumnHero />
            <TextImage01 />
            <AutoSlider />
            <Title Tag="h2" title="ACTIVITY" subTitle="活動概要" />
            <TextImage02
              flexDirection={'row'}
              textAlign={'right'}
              right={0}
              imageSrc={'/images/top_meeting.jpg'}
              imageAlt={'週に1度のミーティング'}
              title="MEETINGS"
              content="週に1度のミーティング"
              description="毎週日曜日の午前10時より2時間、メンバーのビジネスに関する相談会や講義などを行っています。"
            />
            <TextImage02
              flexDirection={'row-reverse'}
              textAlign={'left'}
              left={0}
              imageSrc={'/images/top_seminar.jpg'}
              imageAlt={'ゲスト講義'}
              title="SEMINAR"
              content="ゲスト講義"
              description="ビジネスの最前線で活躍する経営者をゲストとしてお招きし、ビジネスに関する講義を行っています。"
            />
            <TextImage02
              flexDirection={'row'}
              textAlign={'right'}
              right={0}
              imageSrc={'/images/top_event.jpg'}
              imageAlt={'イベント'}
              title="EVENT"
              content="イベント"
              description="経営者同士の交流会を通じたコミュニティ作りや、イベントの企画・主催をしています。"
            />
            <section
              style={{ backgroundColor: '#FAFAFA', padding: '75px 0 0 0' }}
            >
              <Title Tag="h2" title="SEMINAR/EVENT" subTitle="イベント情報" />
              <div className="inner">
                <CategoryProducts
                  dataSrc={postItems}
                  title={'イベント'}
                  slug="event"
                  displayCount={3}
                />
              </div>
            </section>
            <section>
              <Member />
            </section>
            <SNS />
          </div>
        </>
      )}
    </>
  );
}
