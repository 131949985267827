import "src/components/organisms/WordPress/TextImage01.css.tsx.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/WordPress/TextImage01.css.tsx.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE41Uy27bMBC85ysWDgokQClYsp049KVo2mv/gRJXMhuKFEiqclrk3ws+ZMmxEEQXgcvZ3Znhktmw1aV5WsO/GwAubCfZK4Va4ulwA/C7t07Ur6TSyqFyFGzHKiQlugFReYRHEi4MVk5oRaHSsm/DjtEDaVhHYZPtDLZn8GB8UGn/98GYEaF5AL7dZJFVvswqlCmZFZbCbv3lYxpXEgLUOmbcrFMROrXMNEKRUjunWwpF9njJZ7OE2mTvWG8DqtNWRC4GJXPiDx7mWoSSQiEZJTEpGkWEw9ZSqFA5NLOKu1Cx1soRK/4ihSIZWrLqpTG6V5xUWmpD4XYdvmRsiNThW+h+uBaTp8Id41yohsL6HBqOwiEJAzA/vlKfCMdKG+bVktIge6FQSe3rnxU8vFeQn4ci+HBE0Rwdhc2llY8hbVLpG0fey/aW2nA0E+qzDRNySMGHaGCI1awV8pXC6juWzMIv7HH1Fare2NRTonNogjHRsay4FLEPInyGP45Oi3i6nx2RUurqZS4u705gtRQcbp9/FM/Fz2mTGMZFP12LQXB39KsuTNkoelynw5dYeydC8Mz6iVJWOzSR/Hh5Viuf53RHoUhFYna+j6vUcX/ZMC0TyZD+oYqYtQhxhilba9NSMNoxh3fbHcfm/kPrJqdZabXsXZjNby1ywcBWBlEBUxzuWqFIkvD4sO9O90F+Nn8krx8bo4dD3JjGbZyrtyk9T+lXL9J43y/QRUK/u6Db8xjPsJtl7C69XxfY3ShiievbfwI0FJ8PBgAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrow = 'w4obr99';
export var arrowDiv = 'w4obr98';
export var button = 'w4obr97';
export var buttonContainer = 'w4obr94';
export var description = 'w4obr96';
export var descriptionContainer = 'w4obr93';
export var multiColumnGrid = 'w4obr90';
export var multiColumnTile = 'w4obr91';
export var title = 'w4obr95';
export var titleContainer = 'w4obr92';