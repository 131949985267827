import React, { useContext, useEffect, useRef } from 'react';

import { multiColumnGrid, multiColumnTile, titleContainer, descriptionContainer, buttonContainer, title, description, button, arrow, arrowDiv } from './TextImage01.css'
import ViewMoreButton from './ViewMoreButton';

const TextImage01 = React.memo(() => {

    return (
        <>
            <section >
                <div className="inner">
                    <div className={multiColumnGrid} >
                        <div className={multiColumnTile} >
                            <div className={titleContainer}>
                                <div className={title}>「稼ぐ」とは人を幸せにすること<br />
                                    「遊ぶ」とは楽しむ心をもつこと<br />
                                    「貢献する」とは、<br className="sp" />思いやりを持って生きること。
                                </div>
                            </div>
                            <div className={descriptionContainer}>
                                <p className={description}>
                                    CRYINGTIGERとは、「稼ぐ・遊ぶ・貢献する」をバランスよくこなすことを「かっこいい」と定義し、その「かっこいい」を追求する経営者の団体です。<br />
                                    <br />
                                    「稼ぐ」とは、人を幸せにすること。<br />
                                    「遊ぶ」とは、楽しむ心を持つこと。<br />
                                    「貢献する」とは、思いやりを持って生きること。<br />
                                    <br />
                                    これらのうちのどれが欠けても成立しない三位一体の状態をCRYINGTIGERでは「かっこいい」と考えています。
                                </p>
                            </div>
                            <ViewMoreButton />
                        </div>
                        <div className={multiColumnTile} >
                            <div className="imageContainer" style={{
                                overflow: 'hidden',
                                maxWidth: '100%',
                                maxHeight: '100%',
                                marginBottom: '1rem',
                            }}>
                                <picture>
                                    <source type="image/webp" srcSet="/images/top_flag.webp" />
                                    <img style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        objectPosition: 'center'
                                    }} src="/images/top_flag.jpg" alt="flag" />
                                </picture>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
});

export default TextImage01;
