import React from 'react';
import { wrapDiv, ul, li, img, imgDiv, postTitle, copmany, text, titleClass, titleContainer, } from './Member.css'

const dataSrc = [
    {
        id: "1",
        sourceUrl: "/images/about_greeting.png",
        alt: "坂田 裕希",
        name: "坂田 裕希",
        company: "株式会社AirPhoto",
        companyLink: "https://airphoto.jp/",
        description: "東京大学法学部卒業、三菱商事株式会社勤務を経て、2015年に起業。現在、<a class='copmanyText' href='https://airphoto.jp/' target='_blank' >株式会社AirPhoto</a>の代表取締役を務める。CRYINGTIGERのビジョンに共感してビジネス・経営を学び、2020年よりCRYINGTIGERの運営に携わる。2022年より代表に就任。",
    },
    {
        id: "2",
        sourceUrl: "/images/top_saki.jpg",
        alt: "田村 沙生",
        name: "田村 沙生",
        company: "株式会社eirjapan",
        companyLink: "https://belfie.tokyo/",
        description: "宮崎県の高校を卒業後、地元のエステサロン勤務を経て上京。東京の整体・エステ会社に勤務後、2012年に個人事業主として独立。CRYINGTIGERのビジョンの一貫性に感銘を受けてビジネス・経営を学び、2019年に法人化。現在、<a class='copmanyText' href='https://belfie.tokyo/' target='_blank' >株式会社eirjapan</a>の代表取締役を務め、青山で美尻専門店を運営。2022年よりCRYINGTIGERの運営に参画。",

    }
]

const Member = React.memo(() => {

    return (
        <>
            <div className={`inner ${wrapDiv}`}>
                <div className={titleContainer}>
                    <h3 className={titleClass}>運営メンバー</h3>
                </div>
                <ul className={ul}>
                    {
                        dataSrc.map((item: any) => {
                            return <>
                                <li key={item.id} className={li}>
                                    <div className={imgDiv}>
                                        <picture>
                                            <source type="image/webp" srcSet={item.sourceUrl.replace(/\.[^/.]+$/, "") + ".webp"} />
                                            <img className={img} src={item.sourceUrl} alt={item.alt} />
                                        </picture>
                                    </div>
                                    {/* <div><a className={copmany} href={item.companyLink} target={'_blank'} > {item.company}</a></div> */}
                                    <div className={postTitle}>{item.name}</div>
                                    <div className={text} dangerouslySetInnerHTML={{ __html: item.description }} />
                                </li>
                            </>
                        })
                    }
                </ul>
            </div >
        </>
    );
});

export default Member;
