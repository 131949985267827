import "src/components/organisms/WordPress/Member.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/WordPress/Member.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE31UyY7iMBC98xVWj5C6D44CSSCYy3zGXJ24Am4cO7KLge4R/z6yY0LC0pcsVa5Xr5bnxK3b5XeZkn8zQlpud1JTNB0jmYV2e7NVBtG0jJTJJjgus6SPXITIzjiJ0mhGLCiO8i/42I4LIfWuB1yk6dwbK14fdtYctWDkV5qmT5IsknyaZHmXhFfOqCOGJAE8oChokJGiT4OWa9cY27L+U3GEP++0SOcf3n2SAvc3UnuQuz3e/k31CTXSRiIjtdHIpR7RyQIdIV2n+BcjjYKzD/o8OpTNF/UBoLF3UIfconeHPyEt1LFT5jSYT5Z3jPjnk3bkSRmHsfOnltPe5IFMQKm4k46RfB1qeOB3T6A26tjq7cvx3U8lkpi0bqBRjDfoPqQxGqmT3+Bnu4pGhDNSAbWxvM991AKskhpGsKsAextPqKyVml5NveVkrKCVBX5gJLwoV+qHGiaEsmlD131Dn1EOxlPMvPK7O0SVz+rPkmKKvXnELiL2zxLqd3uR3A1/w1gFjbEQcIfFe3ubKuPKZySTaLcCbATPujNxRkkRZBmVOSyR1H4ytFKmPmxfiHGgxUfNCKkus98tCMmJqy2AJlwL8u7HGJdpvSq780eISsY30sv7h5DL7Wz2/Owmdsu7RirT5qqzKKj8ETGPiGNZLYt5f+ryHzTvcIs1BQAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var copmany = 's7m2z85';
export var img = 's7m2z82';
export var imgDiv = 's7m2z81';
export var li = 's7m2z84';
export var p = 's7m2z8a';
export var postTitle = 's7m2z87';
export var text = 's7m2z86';
export var titleClass = 's7m2z89';
export var titleContainer = 's7m2z88';
export var ul = 's7m2z83';
export var wrapDiv = 's7m2z80';